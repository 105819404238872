import React from 'react';
import moment from 'moment';
import { BarChart, Tooltip, Bar, XAxis, YAxis } from 'recharts';

interface ChartProps {
  data : Array<any>;
  maxX : number; // to normalize scale for each participant
}

const SurveysSparkline: React.FC<ChartProps> = ({ data, maxX }: ChartProps) => {
  const getMomentDates = (from : moment.Moment, to : moment.Moment) => {
    let daysArr = [];
    let tempDate = from;
  
    while (!tempDate.isSame(to, 'day')) {
        daysArr.push(tempDate);
        tempDate = moment(tempDate.add(1, 'days').toDate());
    }
  
    return daysArr;
  }


  let imputed_data = [];
  let latest;

  // this should only be triggered for newly created participants
  if (!data) {
    data = [];
  }

  if (data.length === 0) {
    latest = moment();

  } else {
    latest = moment();//moment(data[data.length-1].day);
    data.forEach(e => {e.day = moment(e.day)});
  }

  let earliest = moment(latest)
  earliest = earliest.subtract(13, 'days')//moment(earliest.setDate(earliest.getDate() - 13));
  let dates = getMomentDates(earliest, latest);


  for (let date of dates) {
    const existing = data.find(obj => {
      return obj.day.isSame(date, 'day') 
    })

    imputed_data.push(existing ?? {day : date, surveys : 0})
  }

  imputed_data.map(d => d.day = d.day.valueOf())

  return (<BarChart width={100} height={30} data={imputed_data} className='sparkline'>
            <Tooltip labelFormatter={(label) => moment(label).format('ll')} 
                     position={{ x: 0, y: 30 }} wrapperStyle={{zIndex : 1000}}/>
            <XAxis dataKey="day" hide/>
            <YAxis domain={[0, maxX]} hide/>
            <Bar dataKey="surveys" fill="#8884d8" />
          </BarChart>);
};

export default SurveysSparkline;