import React from 'react';
import '../App.css';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import User from '../models/UserModel';
import Study from '../models/StudyModel';
import { DateText } from './DateText';
import CreateNewStudy from './CreateNewStudy';



interface StudiesListProps {
  data : Array<any>;
}

export default class ListOfStudies extends React.Component<StudiesListProps, {}> {
  columns: ColumnsType<any> = [
    {
      title: 'Name',
      width: '30%',
      render: (s : Study) => { return (<div className='study-info'>
                                          <a className='study-name' href={`study/${s.study_id}`} >{s.name}</a><br/>
                                          <span className='study-num-particpants'>{s.participants.length} participants</span>
                                       </div>
      )},
    },
    {
      title: 'Clinicians',
      filterSearch: true,
      render: (s : Study) => { return ( <>
                                          {s.clinicians.map((u : User) => <span key={u.user_id}>{u.full_name}<br/></span>)}
                                        </>
      )}
    },
    {
      title: 'Created On',
      dataIndex: 'created_on',
      defaultSortOrder : 'descend',
      sorter: (a, b) => a.created_on!.getTime() - b.created_on!.getTime(),
      render: (d) => <DateText date={new Date(d)} />,
    },
    {
      title: 'Role',
      className : 'study-user-role',
      dataIndex: 'user_role',
      filters: [{text : "creator", value : "creator"}, {text : "clinician", value : "clinician"}],
      defaultFilteredValue : ["creator", "clinician"],
      onFilter: (value: any, record : Study) => record.user_role == value,
      //render: e => <></>
    }
  ];

  render() {
    return (
      <>
        {/*<Form.Item label='Role' className='filter-role'>
          <Radio.Group value={'all'} onChange={() => {}} buttonStyle="solid">
            <Radio.Button value="creator">Creator</Radio.Button>
            <Radio.Button value="clinician">Clinician</Radio.Button>
            <Radio.Button value="all">All</Radio.Button>
          </Radio.Group>
          </Form.Item>*/}
        <CreateNewStudy/>

        <Table className="studies-table" columns={this.columns} rowKey="study_id" dataSource={this.props.data} />
      </>
    );
  }
}




