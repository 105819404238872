import React from 'react';

import AppLayout from './AppLayout';
import Study from '../models/StudyModel';
import ListOfStudies from './ListOfStudies';

import '../App.css';
import * as API from '../api';
import User from '../models/UserModel';


interface AllStudiesState {
  studies: Array<Study>;
}

interface ViewProps {
  current_user: User;
}


export default class AllStudiesView extends React.Component<ViewProps, AllStudiesState> {
  constructor(props: any) {
    super(props);
    this.state = { studies: [] };
  }

  async componentDidMount() {
    let response = await API.getUserStudies();
    let data = await response.json();

    let studies_data = data["studies"];
    
    try {
      studies_data = studies_data.filter((value: any, index: number, self: any) =>
        index === self.findIndex((t: any) => (
          t.id === value.id
        ))
      );

    } catch (e) {
      console.log('problem parsing studies', e)
    }

    let studies = studies_data.map((e: any) => new Study(e));

    this.setState({ studies: studies });
  }

  render() {
    return (
      <AppLayout crumbs={[{ title: "My Studies" }]}
        selectedMenu={"studies"}
        current_user={this.props.current_user}>
        <ListOfStudies data={this.state.studies} />
      </AppLayout>
    );
  }
}


