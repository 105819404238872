
import React, { useEffect, useState, useRef } from 'react';
import { Button, Form, Popconfirm, Table, Typography, Upload, UploadProps } from 'antd';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Event } from '../../models/types';
import EditableCell from './StudyConfigEvent';
import { readString } from 'react-papaparse';


interface ConfigProps {
    events: Array<Event>;
    saveConfig : Function;
    uploadProps: UploadProps;
    templates : Array<string>;
    delete_button : React.ReactNode;
}

const StudyConfig: React.FC<ConfigProps> = ({ events, uploadProps, templates, saveConfig, delete_button }: ConfigProps) => {
    const [form] = Form.useForm();
    const [data, setData] = useState(events);
    const [prevData, setPrevData] = useState(events);
    const [isEditing, setEditing] = useState(false);

    const uploadRef = useRef<HTMLInputElement>(null);

    useEffect(() => { refreshRows() }, [data]);
    useEffect(() => { setData(events) }, [events]);

    const refreshRows = () => {
        if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
                form.setFieldValue(data[i].id, { ...data[i] });
            }
        }
    }

    const edit = () => {
        refreshRows();
        setPrevData([...data]);
        setEditing(true);
    };

    const handleSurveyChanged = (name : Array<string | number>, field : string, value : string | number ) => {
        let newData : Event[] = [...data];  
        
        //if (field === 'day' && (value as number) <= 0) value = 1;

        // this is a bad work around
        switch (field) {
            case 'day': newData.filter(i => i.id === name[0])[0]!.day = value as number; break;
            case 'lifetime': newData.filter(i => i.id === name[0])[0]!.lifetime = value as number; break;
            case 'message': newData.filter(i => i.id === name[0])[0]!.message = value as string; break;
            case 'title': newData.filter(i => i.id === name[0])[0]!.title = value as string; break;
            case 'reminder': newData.filter(i => i.id === name[0])[0]!.reminder = value as number; break;
            case 'survey_id': newData.filter(i => i.id === name[0])[0]!.survey_id = value as string; break;
            case 'time': newData.filter(i => i.id === name[0])[0]!.time = value as number; break;
        }

        form.setFieldValue(name, value);
        setData(newData);
    };

    const cancel = () => {
        setEditing(false);
        setData([...prevData]);
        uploadRef.current!.value = '';
    };

    const remove = (record: Partial<Event> & { id: React.Key }) => {
        setData(data.filter(e => e.id !== record.id));
    };

    const addRow = () => {
        const newRow = {
            id: Math.floor(Math.random() * 1000),
            config_id: data.length ? data[data.length - 1].config_id : -1,
            day: data.length ? data[data.length - 1].day + 1 : 1,
            lifetime: 60,
            reminder: 0,
            time: 0,
            message : '',
            title : ''
        }

        setData([...data, newRow]);
        refreshRows();
    }

    const save = async () => {
        saveConfig(data);
        setEditing(false);
    };

    const handleFile = async (event : React.ChangeEvent<HTMLInputElement>) => {

        const file = (event.target as HTMLInputElement).files![0];
        //console.log('file', file)
        if (!file) return;

        const file_data = await file.text()

        let events : Array<Event>;

        if (file.type === "application/json") {
            const config = JSON.parse(file_data);

            events = config['events'];

        } else {
            // csv

            let data = readString(file_data, {
                header : true,
                dynamicTyping : true,
                skipEmptyLines: true,
                complete: (results) => {} // why is this a required param????
            }) as any;
            events = data['data'];
        }

        events.map((e : any, index : number) => e['id'] = index)

        setData(events);
        refreshRows();        
    }

    const columns = [
        {
            title: 'day',
            dataIndex: 'day',
            required: true,
            editable: true,
        },
        {
            title: 'time',
            dataIndex: 'time',
            required: true,
            editable: true,
        },
        {
            title: 'lifetime',
            dataIndex: 'lifetime',
            required: true,
            editable: true,
        },
        {
            title: 'reminder',
            dataIndex: 'reminder',
            required: true,
            editable: true,
        },
        {
            title: 'survey',
            dataIndex: 'survey_id',
            required: false,
            editable: true,
        },
        {
            title: 'title',
            dataIndex: 'title',
            required: false,
            editable: true,
        },
        {
            title: 'message',
            dataIndex: 'message',
            required: false,
            editable: true,
        },
        {
            title: '',
            dataIndex: 'operation',
            render: (_: any, record: Event) => {
                return isEditing ? (
                    <Popconfirm title="Delete row?" onConfirm={() => remove(record)}>
                            <Typography.Link type="danger">
                                <DeleteOutlined />
                            </Typography.Link>
                        </Popconfirm>
                ) : (
                        <span/>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: Event) => ({
                record,
                inputType: (col.dataIndex === 'message' || col.dataIndex === "survey_id" || col.dataIndex === "title") ? 'text' : 'number',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing,
                defaultChecked: col.required,
                updateFunc : handleSurveyChanged,
                templates : templates
            }),
        };
    });

    return (
        <Form form={form} component={false}>
            <div className={`config-form-controls ${isEditing ? 'editing' : ''}`}>
                {!isEditing ? <Button onClick={edit} className='edit-config-btn'>Edit</Button>
                    : <div>
                        <Button onClick={addRow} style={{ marginRight: 8 }}>
                            Add row
                        </Button>
                        <Button onClick={save} style={{ marginRight: 8 }} type='primary'>
                            Save
                        </Button>
                        <Button danger>
                            <Popconfirm title="Sure to cancel?" onConfirm={cancel} >
                                <a>Cancel</a>
                            </Popconfirm>
                        </Button>
                    </div>
                }
                
                <span className='other-buttons'>
                    {isEditing ? <Button style={{marginRight : 10}} onClick={() => uploadRef.current?.click()}><UploadOutlined/> Upload</Button> : ''}
                    <input 
                        type='file' 
                        ref={uploadRef} 
                        multiple={false}
                        onInput={handleFile}
                        accept='.csv,.json'
                        style={{ display: "none" }}/>
                    {delete_button}
                </span>

            </div>

            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                dataSource={data}
                rowKey={'id'}
                columns={mergedColumns}
                onChange={refreshRows}
                rowClassName="editable-row"
                pagination={false}
            />
            <span className='events-table-num'>{events.length} events</span>
        </Form>
    );
};

export default StudyConfig;
