interface HealthData {
  created_by : string;
  data_label : string;
  data_unit : string;
  data_value : number;
  start_time : string;
  end_time : string;
  study_id? : number; 
}

interface LogData {
  message : string;
  created_on : string;
}

interface DataArgs {
  id : string;
  study_id? : number;
  page? : number;
  max_items? : number;
  fields? : Array<string>;
  sort? : string;
}

interface SurveyArgs {
  id : string;
  study_id? : number;
  page? : number;
  max_items? : number;
  sort? : string;
  template_id? : string;
}

interface StudyCode {
  code : string;
  expiration : string;
  created_on : string;
  study_id : number;
}

interface Event {
  id: number;
  config_id: number;
  day: number;
  lifetime: number;
  message: string;
  reminder: number;
  title: string;
  survey_id?: string;
  time: number;
}

interface StatsConfig {
  most_recent : Date;
  least_recent : Date;
  start_date? : Date;
  total_days? : number;
  days_since_start? : number;
  total_completed? : number;
  total_scheduled? : number;

}

type GridCategoryName = 'sound' | 'space' | 'mood' | 'social' | 'custom'; 

interface GridAnswerCategory {
    name : GridCategoryName;
    displayName : string;
    color : string;
}

const grid_categories : Record<GridCategoryName, GridAnswerCategory> = {
    'sound' : { name : 'sound',
                displayName : 'Sound/Noise',
                color : '#41DEBB' },
    'space' : { name : 'space',
                displayName : 'Space/Environment',
                color : '#32ADE6' },
    'mood' :  { name : 'mood',
                displayName : 'Mood',
                color : '#FFB349' },
    'social' : { name : 'social',
                 displayName : 'Social',
                 color : '#FF8E9B' },
    'custom' : { name : 'custom',
                 displayName : 'Custom',
                 color : '#A357D7' },
}

interface ChallengeStats {
  challenege: string;
  count: number;
  category: GridCategoryName;
}

interface CategoryStats { 
  category: string; 
  count: number; 
  challenges: ChallengeStats[];
  percentage: string;
}

interface LocationStats {
  location : string;
  count: number;
  challenges: string[];
  challenege_categories: string[];
}

interface ListeningStats {
  listening : string;
  count: number;
  challenges: string[];
  challenege_categories: string[];
}

interface BreadcrumbItem {
  title : string;
  icon? : React.ReactNode;
  href? : string;
}

export type { HealthData, LogData, DataArgs, SurveyArgs, StudyCode, Event, StatsConfig, GridCategoryName, 
              GridAnswerCategory, ChallengeStats, CategoryStats, LocationStats, ListeningStats, BreadcrumbItem }
export { grid_categories }
