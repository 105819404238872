import React from 'react';

import { AutoComplete, Button, Form } from 'antd';

import '../App.css';
import * as API from '../api';


interface AddExistingParticipantFormState {
  email: string;
  errorText: string;
  autocomplete: {label: string, value: string}[];
  message?: string;
}

interface AddExistingParticipantFormProps {
  study_id: number;
  refreshParticipants: Function;
  cancel: Function;
}

export default class AddExistingParticipantForm extends React.Component<AddExistingParticipantFormProps, AddExistingParticipantFormState> {
  loading: boolean;

  constructor(props: any) {
    super(props);
    this.state = {
      email: '',
      autocomplete: [],
      errorText: ''
    };

    this.loading = false
  }

  async componentDidMount() {
    try {
      let response = await API.getAllParticipants();
      let users = await response.json();

      let all_emails = users.map((u: any) => {return { label : u["email"], value : u["email"] }});
      this.setState({autocomplete : all_emails});
    }
    catch (e) {
      console.log('Error loading autocomplete', e)
    }
  }


  handleSubmit = async () => {
    if (this.loading) return;

    this.loading = true;
    this.forceUpdate();

    let response = await API.addExistingParticipant(this.state.email, this.props.study_id);

    //redirect to urls
    if (response.ok) {
      let data = await response.json();
      this.setState({ message : data });
      this.props.refreshParticipants();
      this.loading = false;

    } else {
      this.loading = false;
      this.setState({ errorText: "There was a problem adding the user." });
    }
  }

  reset = () => {
    this.setState({ message : '', email : '', errorText : ''})
  }

  render() {
    let body;
    if (!this.props.study_id) return;
    if (this.state.message) {
      // TODO: fix
      body = <>
                {this.state.message}<br/><br/>
                <Button onClick={this.reset}>Add another</Button>
             </>
    }
    else {
      body = <Form
        name="basic"
        layout='vertical'
        autoComplete="off"
      >
        <div className='error-text'>{this.state.errorText}</div>
        <Form.Item
          label="Participant email"
          name="existing-email"
          rules={[{ required: true, message: 'Please input a value!' }]}
        >
          <AutoComplete value={this.state.email} onChange={e => this.setState({ email: e })} 
                        options={this.state.autocomplete}
                        filterOption={(inputValue, option) =>
                          option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }/>
        </Form.Item>

        <Form.Item >
          <span className='study-modal-actions'>
            <Button onClick={() => this.props.cancel()}>Cancel</Button>
            <Button type="primary" onClick={this.handleSubmit} loading={this.loading}>Submit</Button>
          </span>
        </Form.Item>
      </Form>
    }

    return body;
  }
}


