import React from 'react';
import '../App.css';
import * as API from '../api';
import AppLayout from './AppLayout';
import User from '../models/UserModel';
import ListOfUsers from './ListOfUsers';
import Study from '../models/StudyModel';
import AddParticipantModal from './AddParticipantModal';

interface AllUsersState {
  users : Array<User>;
  studies : Array<Study>;
}

interface ViewProps {
  current_user : User;
}

export default class AllUsersView extends React.Component<ViewProps, AllUsersState> {
  constructor(props : any) {
    super(props);
    this.state = { 
      users : [],
      studies : []
    };
  }

  async componentDidMount() {
    let response1 = await API.getAllParticipants();
    let users_data = await response1.json();

    let users = users_data.map((e : any) => new User(e));

    let response2 = await API.getUserStudies();
    let studies_data = await response2.json();

    let studies = studies_data["studies"].map((e : any) => new Study(e));

    this.setState( { users : users, studies : studies });
  }

  refreshParticipants = async () => {
    let response1 = await API.getAllParticipants();
    let users_data = await response1.json();

    let users = users_data.map((e : any) => new User(e));

    this.setState( { users : users });
    
  }

  render() {
    if (!this.state.studies) return;
    return (
      <AppLayout crumbs={[{title : "All Participants"}]}
                 selectedMenu={"users"}
                 current_user={this.props.current_user}
                 className='all-users'>
        <AddParticipantModal studies={this.state.studies} chooseStudy={true} refreshParticipants={this.refreshParticipants}/>
        <ListOfUsers data={this.state.users}/>
      </AppLayout>
    );
  }
}

