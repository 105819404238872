import React from 'react';
import '../../App.css';

import User from '../../models/UserModel';
import Study from '../../models/StudyModel';

import { Descriptions, DescriptionsProps, Card, Tag } from 'antd';

import * as API from '../../api';
import { DateText } from '../DateText';
import StudyOptionsMenu from './StudyOptionsMenu';
import StudyConfigModal from './StudyConfigModal';
import AddParticipantModal from '../AddParticipantModal';


interface StudyInfoProps {
  study : Study;
  refreshParticipants : Function;
  updateConfig : Function;
}

export default class StudyInfo extends React.Component<StudyInfoProps, {}> {

  exportHealth = async () => { this.downloadCSVFile(await API.exportAllStudyData(this.props.study.study_id)); }

  downloadCSVFile = async (response : Response) => {
    let data = await response.blob();
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${new Date().toISOString()}_${this.props.study.name}_data.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }


  render() {
    let all_clinicians : Array<any> = this.props.study.clinicians;
    all_clinicians = all_clinicians.map((c : User) => c.full_name)

    let items : DescriptionsProps['items'] = [
      {
        key: '1',
        label: 'Created by',
        children: this.props.study.created_by.full_name,
      },
      {
        key: '2',
        label: 'Participants',
        children: this.props.study.participants.length,
      },
      {
        key: '3',
        label: 'Created',
        children: <DateText date={new Date(this.props.study.created_on)}/>,
      },
      {
        key: '4',
        label: 'Clinicians',
        span : 3,
        children: all_clinicians.join(", "),
      },
      {
        key: '5',
        label: 'Study Schedule',
        span: 2,
        children: ""
      },
      {
        key: '6',
        label: 'Description',
        span : 3,
        style : {minWidth : "100%"},
        children: this.props.study.description ?? "empty",
      },
    ];

    let text;
    let config_button;

    if (!this.props.study.config) {
      text = "not configured";
      config_button = <StudyConfigModal study={this.props.study} updateConfig={this.props.updateConfig}
                                        modal_header={`Add Config for Study ${this.props.study.name}`} button_text='Add config'/>
    } else {
      text = `${this.props.study.config.events.length} events`;
      config_button = <StudyConfigModal study={this.props.study} updateConfig={this.props.updateConfig}
                                        modal_header={`Edit Config for Study ${this.props.study.name}`} button_text='Edit'/>
    }

    items[4] = ({
      key: '5',
      label: 'Study Schedule',
      span : 3,
      children: <span className='user-schedule'>{text} {config_button}</span>,
    })


    return (
      <Card className='study-info'>
        <Descriptions title={<> 
                                <h2 className='study-title'>{this.props.study.name}</h2>
                                {!this.props.study.user_role ? <Tag color="magenta" id='admin-tag'>Viewing as Admin</Tag> : ""}
                                <StudyOptionsMenu study={this.props.study} exportHealth={this.exportHealth}/>
                                <AddParticipantModal studies={[this.props.study]} refreshParticipants={this.props.refreshParticipants}
                                                     canAddExisting={true}/> 
                             </>} 
                      items={items} 
                      column={3}/>
        <span className='study-actions'>
          {/*<Button onClick={this.exportHealth} id='export-study-data'>Export all health data</Button>*/}         
        </span>
      </Card>
    );
  }
}
