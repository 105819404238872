import User from "./UserModel";

export default class Study {
  study_id : number;
  name : string;
  description : string;
  created_by : User;
  created_on : Date;

  clinicians : Array<User>;
  participants : Array<User>;

  config? : any;

  user_role : "clinician" | "creator";

  constructor(data : any) {
    this.study_id = data["id"];
    this.name = data["name"];
    this.description = data["description"];
    this.created_by = new User(data["created_by"]);
    this.created_on = new Date(data["created_on"]);

    let clinician_data = data["clinicians"];
    clinician_data.unshift(data["created_by"]);

    try {
      clinician_data = clinician_data.filter((value: any, index: number, self: any) =>
        index === self.findIndex((t: any) => (
          t.user_id === value.user_id
        ))
      );

    } catch (e) {
      console.log('problem parsing clinicians', e)
    }
  
    this.clinicians = clinician_data.map((c : any) => new User(c));
    this.participants = data["participants"].map((p : any) => new User(p));

    this.user_role = data['user_role'];
    
  }
}