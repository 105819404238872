import { UploadProps } from 'antd';
import { DataArgs, SurveyArgs } from './models/types';

const HOST = process.env.REACT_APP_HOST

function getCookie(name : string) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function eraseCookie(name : string) {   
    document.cookie = name+'=; Max-Age=-99999999;';  
}


async function getCurrentUser() {
    return fetch(`${HOST}/api/user/me`, {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

async function login(username : string, password : string) {
    return fetch(`${HOST}/api/login`, {
        method: "POST",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
        credentials: 'include',
        body: JSON.stringify({"username": username, "password": password})
    });
}

async function logout() {
    return fetch(`${HOST}/api/auth/logout`, {
        method: "POST",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
        credentials: 'include',
    });
}

async function getAllUsers() {
    return fetch(`${HOST}/api/users/`, {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

async function getAllParticipants() {
    return fetch(`${HOST}/api/users/participants`, {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

function getUserByID(id : string, study_id? : number) {
    return fetch(`${HOST}/api/user/${id}${study_id ? `?study_id=${study_id}` : ''}`, {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

function getParticipantOTP(id : string) {
    return fetch(`${HOST}/api/user/${id}/code`, {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

function refreshParticipantOTP(id : string) {
    return fetch(`${HOST}/api/user/${id}/code`, {
        method: "POST",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

function getUserSurveys({id, study_id, page = 1, max_items = 100, sort = '', template_id = ''} : SurveyArgs) {
    return fetch(`${HOST}/api/user/${id}/surveys?page=${page}&max_items=${max_items}` +
                        (study_id ? `&study_id=${study_id}` : '') +
                        (sort ? `&sort=${sort}` : '') +
                        (template_id ? `&template_id=${template_id}` : ''), {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

function getSurveyTemplate(id : string) {
    return fetch(`${HOST}/api/survey/${id}`, {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

function deleteSurveyTemplate(id : string) {
    return fetch(`${HOST}/api/survey/${id}`, {
        method: "DELETE",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

function getUserData({id, study_id, page = 1, max_items = 100, fields = [], sort = ''} : DataArgs) {
    return fetch(`${HOST}/api/user/${id}/data?page=${page}&max_items=${max_items}` + 
                        (fields.length > 0 ? `&fields=${fields.join(',')}` : '') +
                        (study_id ? `&study_id=${study_id}` : '') +
                        (sort ? `&sort=${sort}` : ''), {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

function getAllSurveyTemplateIDs() {
    return fetch(`${HOST}/api/survey/list`, {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

function exportUserSurveys(id : string, study_id? : number) {
    return fetch(`${HOST}/api/user/${id}/surveys/export`+
                        (study_id ? `?study_id=${study_id}` : ''), {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

function getUserLogs(id : string, page : number = 1, max_items : number = 1000, sort : string = '') {
    return fetch(`${HOST}/api/user/${id}/logs?page=${page}&max_items=${max_items}` 
                                                  + (sort ? `&sort=${sort}` : ''), {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

function getAllSurveyTemplates(page = 1, max_items = 10) {
    return fetch(`${HOST}/api/survey/?page=${page}&max_items=${max_items}`, {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

function createSurveyTemplate(name : string, file : any) {
    return fetch(`${HOST}/api/survey/`, {
        method: "POST",
        credentials: "include",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            "X-CSRFToken": getCookie("csrftoken") ?? "",
            'Content-Type': 'application/json',
            "Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")
          },
        body: JSON.stringify({"identifier": name, "template": file})
    });
}

function exportUserData(id : string, study_id? : number) {
    return fetch(`${HOST}/api/user/${id}/data/export`+
                        (study_id ? `?study_id=${study_id}` : ''), {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

function getUserStudies() {
    return fetch(`${HOST}/api/studies/`, {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

function getInviteCode(study_id : number) {
    return fetch(`${HOST}/api/study/${study_id}/code`, {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

function useInviteCode(code : string) {
    return fetch(`${HOST}/api/studies/`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            "X-CSRFToken": getCookie("csrftoken") ?? "",
            'Content-Type': 'application/json',
            "Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")
          },        
        credentials: "include",
        body: JSON.stringify({ "code": code })
    });
}

function createNewStudy(name : string, description : string) {
    return fetch(`${HOST}/api/studies/`, {
        method: "POST",
        credentials: "include",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            "X-CSRFToken": getCookie("csrftoken") ?? "",
            'Content-Type': 'application/json',
            "Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")
          },
        body: JSON.stringify({"name": name, "description": description})
    });
}

function createNewParticipant(name : string, email : string, password : string, study_id : number) {
    return fetch(`${HOST}/api/users/`, {
        method: "POST",
        credentials: "include",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            "X-CSRFToken": getCookie("csrftoken") ?? "",
            'Content-Type': 'application/json',
            "Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")
          },
        body: JSON.stringify({"full_name": name, "email" : email, "username" : email, "study_id" : study_id, "password" : password})
    });
}

function addExistingParticipant(email : string, study_id : number) {
    return fetch(`${HOST}/api/study/${study_id}/participants`, {
        method: "PUT",
        credentials: "include",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            "X-CSRFToken": getCookie("csrftoken") ?? "",
            'Content-Type': 'application/json',
            "Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")
          },
        body: JSON.stringify({ "email": email })
    });
}

function getStudyByID(id : number) {
    return fetch(`${HOST}/api/study/${id}`, {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

function getStudyParticipants(id : number) {
    return fetch(`${HOST}/api/study/${id}/participants`, {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

function exportAllStudyData(id : number) {
    return fetch(`${HOST}/api/study/${id}/data/export`, {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

function createNewConfig(config : any, id : number, participant_id? : string) {
    return fetch(`${HOST}/api/study/${id}/config${participant_id ? `?participant=${participant_id}` : ''}`, {
        method: "POST",
        credentials: "include",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            "X-CSRFToken": getCookie("csrftoken") ?? "",
            'Content-Type': 'application/json',
            "Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")
          },
        body: JSON.stringify(config)
    });
}


function updateConfig(config : any, id : number, participant_id? : string) {
    return fetch(`${HOST}/api/study/${id}/config${participant_id ? `?participant=${participant_id}` : ''}`, {
        method: "PUT",
        credentials: "include",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            "X-CSRFToken": getCookie("csrftoken") ?? "",
            'Content-Type': 'application/json',
            "Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")
          },
        body: JSON.stringify(config)
    });
}


function generateUploadProps(id : number, participant_id? : string) : UploadProps {
    return {
            name: 'file',
            maxCount : 1,
            withCredentials :true,
            action: `${HOST}/api/study/${id}/config/upload${participant_id ? `?participant=${participant_id}` : ''}`,
            headers: {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', ""),
                      "X-CSRFToken": getCookie("csrftoken") ?? "",
                      }
    }
}

function getStudyConfig(id : number, participant_id? : string) {
    return fetch(`${HOST}/api/study/${id}/config${participant_id ? `?participant=${participant_id}` : ''}`, {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

function removeStudyConfig(id : number, participant_id? : string) {
    return fetch(`${HOST}/api/study/${id}/config${participant_id ? `?participant=${participant_id}` : ''}`, {
        method: "DELETE",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

function getStudySurveyStats(id : number) {
    return fetch(`${HOST}/api/study/${id}/stats`, {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

//'api/user/<str:user_id>/data/stats'
//`${HOST}/api/user/${id}/stats` + (study_id ? `?study_id=${study_id}` : '')
function getHealthdataStats(id : string, study_id? : number) {
    return fetch(`${HOST}/api/user/${id}/data/stats` + (study_id ? `?study_id=${study_id}` : ''), {
        method: "GET",
        headers : {"Authorization" : (getCookie("Authorization") ?? "").replaceAll('"', "")},
        credentials: "include"
    });
}

export {getCurrentUser, login, logout, eraseCookie, getAllUsers, getAllParticipants, getUserByID, getUserSurveys, 
        getSurveyTemplate, getAllSurveyTemplateIDs, exportUserSurveys, getInviteCode, useInviteCode, 
        getParticipantOTP, refreshParticipantOTP, createNewParticipant, addExistingParticipant, getUserLogs, getUserData, 
        exportUserData, createNewStudy, getUserStudies, getStudyByID, getStudyParticipants, exportAllStudyData, 
        generateUploadProps, createNewConfig, getStudyConfig, updateConfig, removeStudyConfig, getAllSurveyTemplates, 
        createSurveyTemplate, deleteSurveyTemplate, getStudySurveyStats, getHealthdataStats }