import React from 'react';

import { Button, Modal, Form, Input, Tabs, TabsProps } from 'antd';

import '../App.css';
import * as API from '../api';


interface CreateNewStudyState {
  visible: boolean;
  name: string;
  description: string;
  errorText: string;
  code: string;
}

export default class CreateNewStudy extends React.Component<{}, CreateNewStudyState> {
  loading: boolean;

  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
      name: "",
      description: "",
      errorText: "",
      code: ""
    };

    this.loading = false
  }

  showModal = () => {
    this.setState({ visible: true });
  }

  hideModal = () => {
    this.setState({ name: "", description: "" })
    this.setState({ visible: false });
  }

  handleCreateSubmit = async () => {
    if (this.loading) return

    this.loading = true;
    this.forceUpdate();

    let response = await API.createNewStudy(this.state.name, this.state.description);

    if (response.ok) {
      let data = await response.json();
      window.location.href = `study/${data['id']}`;
    } else {
      this.loading = false;
      this.setState({ errorText: "There was a problem creating a new study." });
    }
  }

  handleCodeSubmit = async () => {
    if (this.loading) return

    this.loading = true;
    this.forceUpdate();

    let response = await API.useInviteCode(this.state.code);

    if (response.ok) {
      let data = await response.json();
      window.location.href = `study/${data['id']}`;
    } else {
      this.loading = false;
      this.setState({ errorText: "There was a problem creating a new study." });
    }
  }

  onChange = (key: string) => {
    //console.log(key);
  };

  render() {

    let create_form = <Form
      name="basic"
      layout='vertical'
      autoComplete="off"
    >
      <div className='error-text'>{this.state.errorText}</div>
      <Form.Item
        label="Study name"
        name="name"
        rules={[{ required: true, message: 'Please input a name!' }]}
      >
        <Input value={this.state.name} onChange={e => this.setState({ name: e.target.value })} />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: false }]}
      >
        <Input.TextArea value={this.state.description} onChange={e => this.setState({ description: e.target.value })} />
      </Form.Item>
      <Form.Item >
        <span className='study-modal-actions'>
          <Button onClick={this.hideModal}>Cancel</Button>
          <Button type="primary" onClick={this.handleCreateSubmit} loading={this.loading}>Submit</Button>
        </span>
      </Form.Item>
    </Form>


    let join_code_form = <Form
      name="basic"
      layout='vertical'
      autoComplete="off"
    >
      <div className='error-text'>{this.state.errorText}</div>
      <Form.Item
        label="Code"
        name="code"
        rules={[{ required: true, message: 'Please input a code!' }]}
      >
        <Input value={this.state.code} onChange={e => this.setState({ code: e.target.value })} />
      </Form.Item>

      <Form.Item >
        <span className='study-modal-actions'>
          <Button onClick={this.hideModal}>Cancel</Button>
          <Button type="primary" onClick={this.handleCodeSubmit} loading={this.loading}>Submit</Button>
        </span>
      </Form.Item>
    </Form>


    const items: TabsProps['items'] = [
      {
        key: '1',
        label: 'Create study',
        children: create_form,
      },
      {
        key: '2',
        label: 'Join with code',
        children: join_code_form,
      },
    ];


    return (
      <>
        <Button type="primary" className="new-study-btn" onClick={this.showModal}>
          + Add New Study
        </Button>
        <Modal
          open={this.state.visible}
          title="Create New Study"
          onOk={this.handleCreateSubmit}
          onCancel={this.hideModal}
          footer={null}
        >
          <Tabs defaultActiveKey="1" items={items} onChange={this.onChange} />

        </Modal>
      </>
    );
  }
}


