import '../../App.css';
import Survey from '../../models/SurveyModel';

import React from 'react';
import Study from '../../models/StudyModel';
import { CategoryStats, ChallengeStats, ListeningStats, LocationStats } from '../../models/types';
import { ChallengeCategoryBar, ListeningContextStackedBar, LocationContextStackedBar, TopChallengesBar } from './GridSurveyPlots';


interface GridSurveyStatsProps {
    user_id : string;
    study? : Study;
    surveys : Survey[];
}


// fixme: add types
interface GridSurveyStatsState {
    hasSurveys : boolean;
    total_challenges : number;
    challenge_counts : ChallengeStats[];
    category_counts : CategoryStats[];
    location_counts : LocationStats[];
    listening_counts : ListeningStats[];
}


export default class GridSurveyStats extends React.Component<GridSurveyStatsProps, GridSurveyStatsState> {
    constructor(props : GridSurveyStatsProps) {
        super(props);
        this.state = { 
            hasSurveys : true,
            total_challenges : 0,
            challenge_counts : [],
            category_counts : [],
            location_counts : [],
            listening_counts : [],

        };
      }

    async componentDidMount() {        
        // aggregate results
        if (this.props.surveys.length > 0) this.generateStats(this.props.surveys);
        else this.setState({ hasSurveys : false });
    }

    generateStats = (surveys : Survey[]) => {
        // plots 1 + 2:
        // count of challenge categories and challenges (grid)
        // optional plot 2 subplot: count of context conditioned on top challenges
        let challenge_counts : ChallengeStats[] = [];
        let total_challenges = 0;

        let location_stats : LocationStats[]= [];
        let listening_stats : ListeningStats[] = [];

        let wearing_aides;

        const location_choices = surveys[0].template_data[0].choices;
        const listening_choices = surveys[0].template_data[1].choices;

        location_choices.map((choice : any) => location_stats.push({location : choice, count : 0, challenges : [], challenege_categories : []}))
        listening_choices.map((choice : any) => listening_stats.push({listening : choice, count : 0, challenges : [], challenege_categories : []}))


        for (let s of surveys) {

            for (let selection of s.associated_survey.reasons) {
                
                let existing_challenege = challenge_counts.find(item => item.challenege === selection.title);
                if (existing_challenege) {
                    existing_challenege.count += 1
                } else {
                    challenge_counts.push({challenege : selection.title, count : 1, category : selection.reasonType});
                }
                total_challenges += 1;
            }

            // iterate through context questions
            let responses = s.data;
            
            const challenge_array = s.associated_survey.reasons.map((item : any) => {return {'challenge' : item.title, 'category' : item.reasonType}});
            const categories_array = s.associated_survey.reasons.map((item : any) => item.reasonType);

            /// Q1: location (single answer)
            let location_response = responses[0]['choiceAnswers'];
            let location = location_response.map((i : any) => s.template_data[0]['choices'][i])[0]

            let existing_location = location_stats.find(item => item.location === location)
            if (existing_location) {
                existing_location.count += 1;
                existing_location['challenges'] = existing_location['challenges'].concat(challenge_array)
                existing_location['challenege_categories'] = existing_location['challenege_categories'].concat(categories_array)
            } else {
                location_stats.push({location : location, count : 1, challenges : challenge_array, challenege_categories : categories_array});
            }
            

            /// Q2: listening
            let listening_response = responses[1]['choiceAnswers'];

            listening_response.map((i : any) => {
                const listening = s.template_data[1]['choices'][i];
                let existing_listening = listening_stats.find(item => item.listening === listening)
                if (existing_listening) {
                    existing_listening.count += 1;
                    existing_listening['challenges'] = existing_listening['challenges'].concat(challenge_array)
                    existing_listening['challenege_categories'] = existing_listening['challenege_categories'].concat(categories_array)
                } else {
                    listening_stats.push({listening : listening, count : 1, challenges : challenge_array, challenege_categories : categories_array});
                }
            })
            
            /// Q3: bool HAs
            let aides_response = responses[2]['booleanAnswer'];
            //console.log('aides_response', aides_response)
        }

        challenge_counts.sort((a, b) => b.count - a.count);

        let sounds = challenge_counts.filter(x => x.category === 'sound');
        let sound_counts = sounds.reduce((accumulator, element) => accumulator + element.count, 0,);

        let spaces = challenge_counts.filter(x => x.category === 'space');
        let space_counts = spaces.reduce((accumulator, element) => accumulator + element.count, 0,);

        let moods = challenge_counts.filter(x => x.category === 'mood');
        let mood_counts = moods.reduce((accumulator, element) => accumulator + element.count, 0,);

        let socials = challenge_counts.filter(x => x.category === 'social');
        let social_counts = socials.reduce((accumulator, element) => accumulator + element.count, 0,);

        let customs = challenge_counts.filter(x => x.category === 'custom');
        let custom_counts = customs.reduce((accumulator, element) => accumulator + element.count, 0,);

        let category_counts: CategoryStats[] = [
            { category : 'sound', challenges : sounds, percentage : ((sound_counts/total_challenges)*100).toFixed(0),
              count : sound_counts },
            { category : 'space', challenges : spaces, percentage : ((space_counts/total_challenges)*100).toFixed(0),
              count : space_counts },
            { category : 'mood', challenges : moods, percentage : ((mood_counts/total_challenges)*100).toFixed(0),
              count : mood_counts },
            { category : 'social', challenges : socials, percentage : ((social_counts/total_challenges)*100).toFixed(0),
              count : social_counts }
        ];

        if (customs.length > 0) {
            category_counts.push({ category : 'custom', challenges : customs, 
                                   percentage : ((custom_counts/total_challenges)*100).toFixed(0),
                                   count : custom_counts })
        }
        
        category_counts.sort((a, b) => b.count - a.count);
        this.setState({ challenge_counts : challenge_counts, 
                        category_counts : category_counts, 
                        location_counts : location_stats,
                        listening_counts : listening_stats,
                        total_challenges : total_challenges });
    }


    render = () => { 
        if (!this.props.user_id || !this.state.hasSurveys) return null;

        return  <div className='grid-plots'>
                    <ChallengeCategoryBar data={this.state.category_counts}/>
                    <TopChallengesBar data={this.state.challenge_counts} total_challenges={this.state.total_challenges}/>
                    <LocationContextStackedBar data={this.state.location_counts} total_challenges={this.state.total_challenges}/>
                    <ListeningContextStackedBar data={this.state.listening_counts} total_challenges={this.state.total_challenges}/>
                </div>
    }
};
