import React from 'react';

import User from '../../models/UserModel';

import AppLayout from '../AppLayout';

import '../../App.css';
import * as API from '../../api';
import SurveyTemplatesTable from './SurveyTemplatesTable';
import CreateNewSurveyTemplate from './CreateNewSurveyTemplate';

interface SurveysState {
  templates : Array<any>;
  error : boolean;
}

interface ViewProps {
  current_user : User;
}

export default class ManageTemplatesView extends React.Component<ViewProps, SurveysState> {
  constructor(props : any) {
    super(props);
    this.state = { templates : [], error : false };
  }

  async componentDidMount() {
    await this.refreshData();
  }


  refreshData = async () => {
    let response = await API.getAllSurveyTemplates();

    if (response.ok) {
      let data = await response.json();
      this.setState({templates : data["templates"]});
    }
  }


  render() {    
    if (!this.state.templates) return;

    return (
      <AppLayout crumbs={[{title : "All Surveys"}]} 
                 className='study-view'
                 current_user={this.props.current_user}>
        <CreateNewSurveyTemplate refresh={this.refreshData}/>
        <SurveyTemplatesTable data={this.state.templates} refresh={this.refreshData} 
                              current_user={this.props.current_user}/>

      </AppLayout>
    );
  }
}

