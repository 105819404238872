import React from 'react';

import { Button, Modal, Tabs, TabsProps } from 'antd';

import '../App.css';
import * as API from '../api';
import Study from '../models/StudyModel';
import CreateNewParticipantForm from './CreateNewParticipantForm';
import AddExistingParticipantForm from './AddExistingParticipantForm';


interface AddParticipantModalState {
  visible: boolean;
  name: string;
  email: string;
  study_id: number;
  password: string;
  errorText: string;
  created_user?: any;
}

interface AddParticipantModalProps {
  studies: Array<Study>;
  chooseStudy?: boolean;
  refreshParticipants : Function;
  canAddExisting : boolean;
}

export default class AddParticipantModal extends React.Component<AddParticipantModalProps, AddParticipantModalState> {
  loading: boolean;

  static defaultProps = {
    chooseStudy: false,
    canAddExisting: false
  };

  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
      name: "",
      email: "",
      errorText: "",
      study_id: this.props.studies[0]?.study_id,
      password: Math.random().toString(36).slice(2, 16),
    };

    this.loading = false
  }


  showModal = () => {
    this.setState({ visible: true });

    if (!this.state.study_id) this.setState({ study_id: this.props.studies[0]?.study_id })
  }

  hideModal = () => {
    this.loading = false;
    this.setState({ visible: false, name: "", email: "", 
                    password: Math.random().toString(36).slice(2, 16), 
                    errorText : "", created_user : undefined })
  }

  handleSubmit = async () => {
    if (this.loading) return;

    this.loading = true;
    this.forceUpdate();

    let response = await API.createNewParticipant(this.state.name, this.state.email, this.state.password, this.state.study_id);

    //redirect to urls
    if (response.ok) {
      let data = await response.json();
      this.setState({created_user : data});
      this.props.refreshParticipants();
      this.loading = false;

    } else {
      this.loading = false;
      this.setState({ errorText: "There was a problem creating a new user." });
    }
  }

  onChange = (key: string) => {
    //console.log(key);
  };

  render() {
    if (!this.props.studies) return;

    const items: TabsProps['items'] = [
      {
        key: '1',
        label: 'Create participant',
        children: <CreateNewParticipantForm studies={this.props.studies} cancel={this.hideModal} chooseStudy={this.props.chooseStudy}
                                            refreshParticipants={this.props.refreshParticipants}/>,
      },
      {
        key: '2',
        label: 'Add existing',
        disabled: !this.props.canAddExisting,
        children: <AddExistingParticipantForm study_id={this.props.studies[0]?.study_id} cancel={this.hideModal}
                                              refreshParticipants={this.props.refreshParticipants}/>,
      },
    ];

    return (
      <>
        <Button type="primary" className="new-user-btn" onClick={this.showModal}>
          + Add New Participant
        </Button>
        <Modal
          open={this.state.visible}
          title="Create New Participant"
          onOk={this.handleSubmit}
          onCancel={this.hideModal}
          footer={null}
        >
          <Tabs defaultActiveKey="1" items={items} onChange={this.onChange} />
        </Modal>
      </>
    );
  }
}


