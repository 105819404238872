import React from 'react';
import '../App.css';
import * as API from '../api';

import { Breadcrumb, Layout, Menu } from 'antd';
import User from '../models/UserModel';
import { UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { BreadcrumbItem } from '../models/types';


const { Header, Content, Footer } = Layout;

interface LayoutProps {
  children: React.ReactNode;
  crumbs: Array<BreadcrumbItem>;
  selectedMenu?: string;
  className? : string;
  current_user : User;
}


export default class AppLayout extends React.Component<LayoutProps, {}> {

  logoutUser = async () => {
    let response = await API.logout();

    if (response.ok) {

      API.eraseCookie('Authorization')
      window.location.href = '/login';
    }

  }


  render() {
    
    const items: MenuProps['items'] = [
      {
        key: '1',
        label: (this.props.current_user && this.props.current_user.username) ? this.props.current_user.username : '',
      },
      {
        key: '4',
        danger: true,
        label: (<div onClick={this.logoutUser}>
                  Logout
               </div>),
      },
    ];


    let nav;
    if (!this.props.current_user.anonymous && this.props.current_user.role === 0) {
      nav = [ { label: (<a href="/">My Studies</a>),
                key: 'studies' },
              { label: (<a href="/users">All Participants</a>),
                key: 'users' },
              { label: (<a href="/surveys/">Surveys</a>),
                key: 'surveys' },
              { label: (<a href="/admin/">Admin</a>),
                key: 'admin' },
                
            ]
    } else if (this.props.current_user.user_id) {
      nav = [ { label: (<a href="/">My Studies</a>),
                key: 'studies' },
              { label: (<a href="/users">All Participants</a>),
                key: 'users' },
              { label: (<a href="/surveys/">Surveys</a>),
                key: 'surveys' },
            ]
    } else {
      nav = [ { label: (<a href="/accounts/login/">Login</a>),
                key: 'login' },
              { label: (<a href="/accounts/signup/">Signup</a>),
                key: 'signup' },
            ]
    }

    return (
      <Layout className={`layout ${this.props.className}`}>
      <Header style={{ display: 'flex', alignItems: 'center' }}>
        <div className="demo-logo" />
        <Menu
          className="header-nav"
          theme="dark"
          mode="horizontal"
          selectedKeys={[this.props.selectedMenu ?? '']}
          items={nav}
        />
        <span className='currentUser'>
          { this.props.current_user.username ?
              <Dropdown menu={{ items }}>
                <a onClick={(e) => e.preventDefault()} className='user-icon'>
                  <UserOutlined />
                </a>
              </Dropdown> :
              null
          }

          {/*this.props.current_user.username ?? ""*/}
        </span>
      </Header>
      <Content style={{ padding: '0 50px' }}>
        <Breadcrumb style={{ margin: '16px 0' }} 
                    items={this.props.crumbs}>
        </Breadcrumb>
        <div className="site-layout-content">
          {this.props.children}
        </div>
      </Content>
      <Footer className='footer-text'>© 2023 GN Store Nord A/S, All Rights Reserved</Footer>
    </Layout>
  );
  }
}
